<template>
  <v-container fill-height fluid pl-0 pr-0>
    <v-row align="center" class="justify-center">
      <v-btn
            align='center'
            text
            color="primary" @click="print">Print
      </v-btn>
    </v-row>
    <v-row id="printMe">
       <v-card
        class="mx-auto mt-0"
        width="1000"
      >
        <v-card-title class="justify-center">
            <span class="title font-weight-light justify-center" v-if="medication[0]">{{medication[0].resident.fullName}}</span>
        </v-card-title>
      </v-card>
      <v-card
        class="mx-auto my-2"
        max-width="1000"
        width="1000"
      >
        <v-card-text>
            <div class="text--primary">
              <p><b class="pr-8">Medication:</b> {{medication[0]['resident'].medication}}</p>
              <p><b class="pr-8">Medication name:</b> {{medication[0].name}}</p>
              <p><b class="pr-8">Directive: </b>{{medication[0].directive}}</p><br>
              <p><b class="pr-8">Description:</b> {{medication[0].description}}</p><br>
              <p><b class="pr-8">Actions:</b> {{medication[0].ation}}</p><br>
              <p><b class="pr-8"> Comment:</b> {{medication[0].comment}}</p><br>
              <p><b class="pr-8"> Quantity  Given:</b> {{medication[0].quantityGiven}}</p><br>
              <p><b class="pr-8"> Time Given:</b> {{medication[0].timeGiven}}</p><br>
            </div>
        </v-card-text>
        <img height="50" width="50" src="../../assets/logo.png">
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import medicationAdministrationApi from '../../services/api/MedicationAdministrationApi';
import residentApi from '../../services/api/ResidentApi';
export default {
    name: 'medicationDetail',
    data() {
        return {
            medication: '',
            resident: '',
            medicationId: '',
            totalMedication:''
        }
    },
    computed: {

    },
    created() {
        this.medicationId = this.$route.params.medicationId;
        this.totalMedication = this.$route.params.totalMedications;
        this.get_medication_list_administration();
    },
    methods: {
        get_medication_list_administration () {
          this.loading = true
          return new Promise((resolve, reject) => {
              if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homeId = ''
                  }
                  else{
                    homeId = localStorage.getItem('homeId')
                  }
              medicationAdministrationApi.getRegisteredMedication({page: '', count:this.totalMedication, id:'', home:homeId,q:''}) 
                .then(medications => {
                    this.medication = medications.result.filter(item => item.id === this.medicationId)
                })
                .catch(error => {
                    return reject(error)
                })
              setTimeout(() => {
                this.loading = false
                }, 1000)
          })
        },
        load_more(residentId){
          this.residentId = residentId;
          return new Promise((resolve, reject) => {
              residentApi.getResident({residentId})
                .then(resident => {
                    this.resident = resident.result
                })
                .catch(error => {
                    return reject(error)
                })
          })
        },
        print () {
          this.$htmlToPaper('printMe');
        }
    }
}
</script>

<style scoped>

</style>